import service from '@/utils/service'

export function getCurrentWbs(current) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_process_forDitto.php?type=process&process=' + current,
    method: 'get'
  })
}

export function getWorkLogList(issueId) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_worklog_jira_forCloud.php?issueid=' + issueId,
    method: 'get'
  })
}

export function getWbsList(parent) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_process_forDitto.php?type=process&parent=' + parent,
    method: 'get'
  })
}

export function getProjectList(projectTitle) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_process_forDitto.php?type=project&title=' + projectTitle,
    method: 'get'
  })
}

export function getProjectListByProjectId(projectId) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_process_forDitto.php?type=project&project=' + projectId,
    method: 'get'
  })
}

export function getSiteList(projectId) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_process_forDitto.php?type=site&project=' + projectId,
    method: 'get'
  })
}

export function getTechnologyDomainList() {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_rtn_skillset.php',
    method: 'get'
  })
}

export function saveWorkLog(data) {
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_saving_worklog_forCloud.php',
    method: 'post',
    data: data
  })
}

export function deleteWorkLog(worklogId) {
  const data = { option: 'delete', worklogID: worklogId};
  return service({
    baseURL: process.env.VUE_APP_IBIZA_SERVER,
    url: '/elisa_brand_new/api/api_saving_worklog_forCloud.php',
    method: 'post',
    data: data
  })
}